import React, { useState, useEffect } from 'react';
import './WhySection.css';

function WhySection() {
    const [currentSubsection, setCurrentSubsection] = useState('boxes'); // Initial state shows the boxes

    useEffect(() => {
        const handleScroll = (event) => {
            const whySection = document.querySelector('.why-section');
            const rect = whySection.getBoundingClientRect();

            // Checking if the "Why Section" is in the viewport
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                if (event.deltaY > 0) {
                    // Scrolling down, show description after boxes
                    if (currentSubsection === 'boxes') {
                        setCurrentSubsection('description');
                        event.preventDefault(); // Prevent scrolling to next section
                    }
                } else {
                    // Scrolling up, show boxes after description
                    if (currentSubsection === 'description') {
                        setCurrentSubsection('boxes');
                        event.preventDefault(); // Prevent scrolling to previous section
                    }
                }
            }
        };

        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [currentSubsection]);

    return (
        <section className="why-section">
            <div className="left-heading">
                <h2>Why Mesdo?<br />Why it matters?</h2>
            </div>
            <div className="right-content">
                {currentSubsection === 'boxes' ? (
                    <div className="metrics-section">
                        <div className="box">
                            <div className="metric">5M+</div>
                            <div className="title">Critical Workforce Shortage</div>
                            <div className="body">More than 5 million allied health positions remain unfilled, severely impacting India's healthcare delivery system.</div>
                        </div>
                        <div className="box">
                            <div className="metric">70%</div>
                            <div className="title">Disconnected and Outdated</div>
                            <div className="body">With 70% of the healthcare industry offline, merit-based hiring suffers, leaving many qualified professionals overlooked.</div>
                        </div>
                        <div className="box">
                            <div className="metric">68%</div>
                            <div className="title">High Vacancy Rates</div>
                            <div className="body">68% of specialist doctor positions at Community Health Centres remain vacant, crippling rural healthcare services.</div>
                        </div>
                        <div className="box">
                            <div className="metric">56 Days</div>
                            <div className="title">Stretched Hiring Timelines</div>
                            <div className="body">Healthcare positions remain vacant for an average of 56 days, prolonging staff shortages and affecting patient care.</div>
                            
                        </div>
                    </div>
                ) : (
                    <div className="description-section">
                        <p>
                            <strong>Mesdo</strong> is a digital platform designed to modernize how healthcare professionals network, find jobs, and stay informed...
                        </p>
                        {/* Add more description content here */}
                    </div>
                )}
                <div className="scroll-bar">
                    <div className={`scroll-bar-inner ${currentSubsection === 'description' ? 'full' : ''}`}></div>
                </div>
            </div>
        </section>
    );
}

export default WhySection;
