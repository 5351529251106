import React, { useState } from 'react';
import './FormModal.css';

function FormModal({ isOpen, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        age: '',
        occupation: '',
    });

    const [errors, setErrors] = useState({});
    const [isFormFilled, setIsFormFilled] = useState(false);

    const validateFields = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Full name is required';
        }
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }
        if (!formData.age) {
            newErrors.age = 'Please select your age';
        }
        if (!formData.occupation) {
            newErrors.occupation = 'Occupation is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            setIsFormFilled(updatedData.name && updatedData.email && updatedData.age && updatedData.occupation);
            return updatedData;
        });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear error for the field being edited
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {
            try {
                const response = await fetch("https://script.google.com/macros/s/AKfycbxboe4XgXXu1crXgpqhxO8iF4CYz__SwoVZlNANgOHxXXz3qX_w5JbdLcL_jzkfIqDv/exec", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: new URLSearchParams({
                        name: formData.name,
                        email: formData.email,
                        age: formData.age,
                        occupation: formData.occupation
                    })
                });

                const result = await response.json();

                if (result.status === "success") {
                    // Redirect to WhatsApp and reset form if submission is successful
                    window.open("https://chat.whatsapp.com/JPOC6zsiMue9IDlKbCXIPM", "_blank");
                    setFormData({ name: '', email: '', age: '', occupation: '' });
                    setIsFormFilled(false);
                    onClose();
                } else {
                    console.error(result.message || "Failed to submit data.");
                }
            } catch (error) {
                console.error("An error occurred while saving data. Please try again.");
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="overlay" onClick={onClose}>
            <div className="form-modal" onClick={(e) => e.stopPropagation()}>
                <button className="button--icon" onClick={onClose}>
                    &times;
                </button>

                <div className="modal__body">
                    <form onSubmit={handleSubmit}>
                        <div className="input">
                            <label className="input__label">Full Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={`input__field ${errors.name ? 'input__field--error' : ''}`}
                            />
                            {errors.name && <p className="input__error">{errors.name}</p>}
                        </div>

                        <div className="input">
                            <label className="input__label">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`input__field ${errors.email ? 'input__field--error' : ''}`}
                            />
                            {errors.email && <p className="input__error">{errors.email}</p>}
                        </div>

                        <div className="input">
                            <label className="input__label">Age</label>
                            <select
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                className={`input__field ${errors.age ? 'input__field--error' : ''}`}
                            >
                                <option value="">Select your age</option>
                                <option value="Less than 16">Less than 16</option>
                                {[...Array(55).keys()].map(age => (
                                    <option key={age + 16} value={age + 16}>{age + 16}</option>
                                ))}
                                <option value="More than 70">More than 70</option>
                            </select>
                            {errors.age && <p className="input__error">{errors.age}</p>}
                        </div>


                        <div className="input">
                            <label className="input__label">Occupation</label>
                            <input
                                type="text"
                                name="occupation"
                                value={formData.occupation}
                                onChange={handleChange}
                                className={`input__field ${errors.occupation ? 'input__field--error' : ''}`}
                            />
                            {errors.occupation && <p className="input__error">{errors.occupation}</p>}
                        </div>

                        <div className="modal__footer">
                            <button
                                type="submit"
                                className="button--primary"
                                disabled={!isFormFilled}
                            >
                                Join WhatsApp Community
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FormModal;
