import React from 'react';
import './Section2.css'; // Import the associated CSS file

function Section2() {
  return (
    <section className="section2">
      <div className="container">
        <p className="section2-text">
          <span className="section2-highlight">Our mission is simple: </span>
          to connect, empower, and advance the healthcare community.
        </p>
      </div>
    </section>
  );
}

export default Section2;
