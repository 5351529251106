// Updated BenefitSection.js
import React from 'react';
import './BenefitSection.css';

const BenefitSection = () => {
    return (
        <section className="benefit-section container">
            <h2 className="benefit-heading">
                How <span className="gradient-text">Mesdo</span> Can Help You <br />
                Elevate Your Healthcare <br />
                Journey.
            </h2>
            <div className="benefit-container">
                <div className="blob"></div> {/* Blob effect */}
                <div className="benefit-boxes">
                    <div className="benefit-box">
                        <div className="text-group">
                            <h3 className="title">Expand Your Job Opportunities By</h3>
                            <div className="metric">50%</div>
                        </div>
                        <p className="description">
                            With up to 50% of healthcare professionals relying on personal networks, many qualified candidates are left out. Mesdo connects you with job opportunities that wouldn’t normally reach you, helping you access a wider range of positions.
                        </p>
                    </div>
                    <div className="benefit-box">
                        <div className="text-group">
                            <h3 className="title">Reduce Job Search Time By</h3>
                            <div className="metric">30%</div>
                        </div>
                        <p className="description">
                            Healthcare professionals often face slow and inefficient job search processes. Mesdo’s streamlined platform allows you to discover relevant jobs faster, cutting down the time spent on manual applications compared to traditional methods.
                        </p>
                    </div>
                    <div className="benefit-box">
                        <div className="text-group">
                            <h3 className="title">Boost Your Network Connections By</h3>
                            <div className="metric">3x</div>
                        </div>
                        <p className="description">
                            Many professionals are limited by local or existing connections, but Mesdo helps you grow your network by up to three times, giving you access to a broader pool of professionals for collaboration, mentorship, and opportunities.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BenefitSection;
