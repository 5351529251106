import React from 'react';
import './Footer.css';

const FooterSection = () => {
    return (
        <footer className="footer-section">
            <div className="text-layer-wrapper">
                <div className="coming-soon top-layer">Coming Soon</div>
                <div className="coming-soon bottom-layer">Coming Soon</div>
            </div>
        </footer>
    );
};

export default FooterSection;
