import React, { useEffect, useRef } from 'react';
import './FeatureCarousel.css'; // Import the associated CSS file
import feature1 from '../assets/Feature1.svg';
import feature2 from '../assets/Feature2.svg';
import feature3 from '../assets/Feature3.svg';
import feature4 from '../assets/Feature4.svg';
import feature5 from '../assets/Feature5.svg';
import feature6 from '../assets/Feature6.svg';
import feature7 from '../assets/Feature7.svg';
import feature8 from '../assets/Feature8.svg';

function FeatureCarousel() {
    const directions = useRef([1, -1, 1, -1, 1]); // Direction of each column's movement
    const lastScrollTop = useRef(window.scrollY); // Keep track of the last scroll position
    const carouselRef = useRef(null); // Reference to the carousel section

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.scrollY;

            // Determine scroll direction
            const scrollDirection = currentScrollTop > lastScrollTop.current ? 1 : -1;
            lastScrollTop.current = currentScrollTop;

            const features = document.querySelectorAll('.column');

            // Apply the parallax effect to each column
            features.forEach((column, index) => {
                const directionMultiplier = directions.current[index] * scrollDirection;

                // Get the current translateY value of the column
                let translateValue = parseFloat(column.style.transform.replace('translateY(', '').replace('px)', '')) || 0;

                // Move by smaller increments based on the scroll direction
                translateValue += directionMultiplier * 1.1; // Smaller step for smoother movement

                // Restrict movement within -20px and 20px
                translateValue = Math.max(Math.min(translateValue, 20), -20);

                column.style.transform = `translateY(${translateValue}px)`;
            });
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // When the carousel section is visible, attach the scroll listener
                    window.addEventListener('scroll', handleScroll);
                } else {
                    // When the carousel is out of view, detach the scroll listener
                    window.removeEventListener('scroll', handleScroll);
                }
            });
        };

        // Adjust the threshold to trigger earlier and end later
        const observer = new IntersectionObserver(observerCallback, {
            threshold: Array.from({ length: 11 }, (_, i) => i * 0.05), // Creates thresholds from 0.0 to 1.0 in 0.1 increments
        });

        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }

        // Clean up the observer and event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
            observer.disconnect();
        };
    }, []);

    return (
        <section className="feature-carousel" ref={carouselRef}>
            <div className="column">
                <img src={feature1} alt="Feature 1" className="feature feature1" />
            </div>
            <div className="column">
                <img src={feature2} alt="Feature 2" className="feature feature2" />
                <img src={feature3} alt="Feature 3" className="feature feature3" />
            </div>
            <div className="column">
                <img src={feature4} alt="Feature 4" className="feature feature4" />
                <img src={feature5} alt="Feature 5" className="feature feature5" />
            </div>
            <div className="column">
                <img src={feature6} alt="Feature 6" className="feature feature6" />
            </div>
            <div className="column">
                <img src={feature7} alt="Feature 7" className="feature feature7" />
                <img src={feature8} alt="Feature 8" className="feature feature8" />
            </div>
        </section>
    );
}

export default FeatureCarousel;
