import React, { useState } from 'react';
import './ContactSection.css';

const ContactSection = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload

        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycbwUb0wXfVbaU8avGXHo6s8P6GQuB1DAC2My4Jhk6fWzIZ6dVnpInt-Jt6vVWT-6-JcQfQ/exec', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: email,
                }),
            });

            const result = await response.json();

            if (result.result === 'success') {
                setMessage('Email submitted successfully!');
                setEmail(''); // Clear the input
            } else {
                setMessage('Failed to submit email.');
            }
        } catch (error) {
            console.error('Error submitting email:', error);
            setMessage('An error occurred.');
        }
    };

    return (
        <section className="contact-section">
            <div className="contact-column">
                <h2 className="contact-heading">Contact Us.</h2>
                <p className="contact-description">
                    Get product updates and news in your inbox. No spam.
                </p>
            </div>
            <div className="email-column">
                <form onSubmit={handleSubmit}>
                    <div className="email-box">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="example@abcd.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className="send-button">
                            <div className="svg-wrapper-1">
                                <div className="svg-wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path
                                            fill="currentColor"
                                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <span>Send</span>
                        </button>
                    </div>
                </form>
                {message && <p className="disclaimer">{message}</p>}
            </div>
        </section>
    );
};

export default ContactSection;
