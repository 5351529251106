import React, { useState, useEffect } from 'react';
import './Hero.css';
import heroImage from '../assets/Main.svg'; // Import the image

function Hero() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Update the cursor position on mouse move
  useEffect(() => {
    const updateCursor = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', updateCursor);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('mousemove', updateCursor);
    };
  }, []);

  return (
    <section className="hero">
      <div className="container">
        <div className="hero-text">
          <h1 className="hero-heading">
            Connecting Healthcare,<br /> Empowering Professionals
          </h1>
          <p className="hero-subheading">
            A comprehensive platform for healthcare professionals to network, exchange knowledge, search for jobs, and stay up to date with industry news.
          </p>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="Hero Graphic" />
        </div>
      </div>

      {/* Swirl Cursor Element */}
      <div
        className="swirl-cursor"
        style={{
          left: `${cursorPosition.x}px`,
          top: `${cursorPosition.y}px`,
        }}
      ></div>
    </section>
  );
}

export default Hero;
